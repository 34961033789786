const BeforeMoney = () => (
  <div className="w-full flex flex-col justify-center m-auto text-white leading-relaxed bg-[#123a4c]">
    <p className="text-5xl w-full m-auto font-normal mt-8 mb-8 text-center">
      Tempo-rahoitus ja Momentum
    </p>
    <p className="mb-4 w-[85%] m-auto">
      Tutustu huolellisesti Business Finlandin rahoitusehtoihin ennen
      Tempo-rahoituksen hakemista. Business Finland käyttää rahoitusehtojen
      lisäksi muitakin kriteereitä myönteisen rahoituspäätöksen aikaansaamiseksi
      ja hankkeiden pisteyttämiseksi.
    </p>

    <p className="pt-8 w-[85%] m-auto">
      Tempo-hakemuksessa hankkeelle on määriteltävä 2-5 tavoitetta, joiden
      toteutumista voidaan mitata konkreettisesti. Tavoitteiden tulee keskittyä
      yrityksen liiketoiminnan kehittymiseen ja kansainväliseen kasvuun.
      Tavoitteiden toteutumista seurataan Business Finlandin toimesta ja niiden
      saavuttamisella tulee olemaan merkittävä vaikutus mahdollisten
      Tempo-rahoituksen jälkeisten rahoitusten myöntämiselle.
    </p>

    <p className="pt-6 w-[85%] m-auto">
      Erilaisten rahoitusvaihtoehtojen keskellä voi olla hankalaa erottaa mikä
      vaihtoehto sopisi omaan tilanteeseen parhaiten.
    </p>
    <p className="pt-6 w-[85%] m-auto">
      Sopimattoman rahoituksen hakeminen väärällä hetkellä johtaa lähes varmasti
      hakemuksen hylkäämiseen ja yrittäjien arvokkaan työajan hukkaan valumiseen
      hakemuksen laatimisen ja liitteiden keräämisen osalta. Tempo-rahoituksen
      kohdalla lyhyessä hakemuksessa on pystyttävä ilmaisemaan kaikki olennainen
      yrityksen kansainvälistymisen suunnitelmista, markkinoiden tuntemuksesta
      ja kilpailukyvystä.
    </p>
    <p className="pt-6 w-[85%] m-auto">
      <i>Momentum</i> on erikoistunut yrittäjien auttamisessa oikean
      instrumentin valinnassa ja rahoituksen järjestämisessä. Momentum tuntee
      tarkoin Tempo-rahoituksen ja muut Business Finlandin rahoitusvaihtoehdot
      ja osaa ohjeistaa oikean instrumentin valinnassa. Ota huoletta yhteyttä ja
      anna Momentumin hoitaa rahoituksen hankinta puolestasi.
      Rahoitusasiantuntijamme keskustelevat mielellään kanssasi erilaisista
      vaihtoehdoista. Yhteydenotto ja alkukartoitus ovat aina ilmaisia.
    </p>
  </div>
);

export default BeforeMoney;
