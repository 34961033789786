import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import InfoSection from "./components/InfoSection/InfoSection";
import MoneySection from "./components/MoneySection/MoneySection";
import Requirements from "./components/Requirements/Requirements";
import Usage from "./components/Usage/Usage";
import Conditions from "./components/Conditions/Conditions";
import Faq from "./components/Faq/Faq";
import BeforeMoney from "./components/BeforeMoney/BeforeMoney";
import ContactForm from "./components/ContactForm/ContactForm";
import Footer from "./components/Footer/Footer";

const App = () => {
  return (
    <div className="h-screen w-screen overflow-x-hidden">
      <div className="md:w-[1280px] m-auto">
        <Navbar />
        <Hero />
        <InfoSection />
        <MoneySection />
        <Requirements />
        <Usage />
        <Conditions />
        <Faq />
        <BeforeMoney />
        <ContactForm />
        <Footer />
      </div>
    </div>
  );
};

export default App;
