import { useState } from "react";
import tempoAPI from "../../api/tempoAPI";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [pot, setPot] = useState("");


  const [sent, setSent] = useState(false);

  const sendLead = async () => {
    if (pot.length > 1) return;

     const resp = await tempoAPI.sendLead({
      name,
      company,
      email,
      phone,
      message,
    }); 

   // const resp = true;

    if(resp) { setSent(true); }


  };

  return (
    <div className="flex flex-col pt-12 pb-24 px-2 md:px-24 bg-[#123a4c] text-white">
      <p className="text-3xl md:text-5xl mb-6">Ota Yhteyttä!</p>
      <label>Nimi</label>
      <input
        onChange={(e) => setName(e.target.value)}
        value={name}
        className="bg-transparent border border-white rounded h-[50px] p-4 mb-4"
      />
      <label>Yrityksen nimi tai Y-Tunnus</label>
      <input
        onChange={(e) => setCompany(e.target.value)}
        value={company}
        className="bg-transparent border border-white rounded h-[50px] p-4 mb-4"
      />
      <label>Sähköposti</label>
      <input
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        className="bg-transparent border border-white rounded h-[50px] p-4 mb-4"
      />
      <label>Puhelin</label>
      <input
        onChange={(e) => setPhone(e.target.value)}
        value={phone}
        className="bg-transparent border border-white rounded h-[50px] p-4 mb-4"
      />
      <label>Kuvaus</label>
      <input
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        className="bg-transparent border border-white rounded h-[50px] p-4 mb-4"
      />
      <label className="hidden">Pot</label>
      <input
        onChange={(e) => setPot(e.target.value)}
        value={pot}
        className="hidden bg-transparent border border-white rounded h-[50px] p-4 mb-4"
      />

      {sent && (<p className="text-2xl"><strong>Kiitos, olemme teihin pian yhteydessä.</strong></p>)}

      {!sent && (<button onClick={sendLead}  className="mr-auto p-4 bg-[#0c2a38] text-white rounded hover:bg-[#081d27]"> Lähetä </button>)}



    </div>
  );
};

export default ContactForm;
