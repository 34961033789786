const Requirements = () => (
  <div className="w-full flex flex-col justify-center m-auto text-white leading-relaxed pb-16 bg-[#123a4c] section-1">
    <p className="text-5xl w-[80%] m-auto font-normal mt-8 mb-16 text-center">
      Mitä vaaditaan Tempo-rahoituksen saamiseksi?
    </p>
    <p className="mb-4 w-[80%] m-auto">
      Business Finland asettaa tiettyjä ehtoja Tempo-rahoituksen saamiseksi.
      Myönteisen rahoituspäätöksen saamiseksi yritykseltä vaaditaan ainakin:
    </p>
    <ul className="m-auto w-[80%] list-disc">
      <li className="ml-8">
        Innovatiivinen tuote tai palvelu sekä selkeä kasvuvisio ja kilpailuetu
        kansainvälisillä markkinoilla. Kasvuvision ja kilpailuedun tunnistaminen
        vaatii yleensä jo merkittävää tuntemusta kohdemarkkinoista, niiden
        koosta sekä markkinoilla toimivista kilpailijoista
      </li>
      <li className="ml-8">
        Vahva tavoite merkittävän kansainvälisen kasvun saavuttamiseksi. Tavoite
        ja kyky saavuttaa tavoite on myös kyettävä uskottavasti perustelemaan
      </li>
      <li className="ml-8">
        Suomessa työskentelevä, vähintään kahden henkilön sitoutunut tiimi.
        Tiimin kyvykkyyttä saavuttaa asetetut tavoitteet arvioidaan Business
        Finlandin toimesta
      </li>
      <li className="ml-8">
        30 000 euroa oman pääoman ehtoista rahoitusta ja varmistettu projektin
        aikainen kokonaisrahoitus
      </li>
      <li className="ml-8">Toteutuskykyinen rahoitussuunnitelma</li>
      <li className="ml-8">
        Yleiset edellytykset kannattavaan liiketoimintaan ja kasvuun
      </li>
      <li className="ml-8">Ei aikaisemmin myönnettyä Tempo-rahoitusta</li>
      <li className="ml-8">Hakijayrityksen tulee olla osakeyhtiömuotoinen</li>
    </ul>

    <p className="pt-8 w-[80%] m-auto">
      <i>Tempo</i>-rahoitusta haetaan Business Finlandin nettisivuilta
      asiointipalvelusta. Itse hakemuksen lisäksi hakijan tulee täyttää ja
      liittää hakemukseen erillinen projektisuunnitelma Tempo-projektissa
      suoritettavista toimenpiteistä. Tempo-hakemus ja projektisuunnitelma on
      aina syytä valmistaa erityistä huolellisuutta noudattaen. Liitteeksi
      tarvitaan myös enintään kaksi kuukautta vanha kirjanpitoajo. Mikäli
      hakemuksen tiedoissa on puutteita tai epäselvyyksiä,{" "}
      <i>Business Finland </i>voi lähettää lisäselvityspyynnön hakijalle.
    </p>

    <p className="pt-6 w-[80%] mb-4 m-auto">
      Tempo-rahoitusta hakevan yrityksen tuotteen tai palvelun tulee olla jo
      lähellä kansainvälistä markkinapotentiaalia. Tempo-projektin päättyessä
      yrityksellä olisi hyvä olla jo oikeita asiakkaita kohdemarkkinoilla.
      Business Finland arvottaa rahoitushakemuksia myös kilpailuedun ja
      innovaation suojattavuuden perusteella. Hakijayrityksen tulisi tuntea jo
      kohdemarkkinaansa ja sen kokoa, kilpailijoita sekä heidän tuotteita ja
      palveluita. Oman innovaation erottuminen edukseen kilpailijoihin
      verrattuna katsotaan merkittäväksi eduksi. Mikäli markkinoiden muut
      toimijat eivät jonkin liiketoimintaa suojaavan seikan vuoksi pysty
      replikoimaan hakijayrityksen toimintatapoja tai innovaatiota tulisi tämä
      pystyä perustelemaan hakemuksessa. Olemassa olevat kiinnostuneet asiakkaat
      tai esimerkiksi sijoittajat toimivat oivina keinoina osoittaa
      hakijayrityksen kansainvälinen kasvupotentiaali.
    </p>
  </div>
);

export default Requirements;
