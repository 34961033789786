import momentum from "../../assets/momentum.svg";

const Footer = () => {
  return (
    <div className="p-12 flex flex-col bg-[#e9e6d3]">
      <div className="grid md:grid-cols-2 sm:grid-cols-1 mb-16">
        <div>
          <h1 className="text-4xl my-8">Tietoja sivustosta</h1>
          <p className="pb-8">
            Temporahoitus.fi - sivustoa ylläpitää ja kehittää Momentum Holdings
            Oy.
          </p>
          <p className="pb-8">
            Momentum Holdings Oyn missiona on auttaa yrityksiä menestymään sekä
            raottamaan globaalia markkinaa tarjoamalla asiantuntija palveluita
            niin rahoituksen, kun yrityksen kannattavuuden parantamisessa.
          </p>
          <p className="pb-8">
            Meistä voit lukea lisää{" "}
            <a className="underline" href="https://momentumhelsinki.fi/">
              sivustollamme
            </a>
          </p>
        </div>
        <div className="mx-auto">
          <h1 className="text-4xl my-8">Mukana kasvussa</h1>
          <img className="h-[100px]" src={momentum} />
        </div>
      </div>
      <div className="m-auto">
        <span>© Momentum Holdings Oy 2023 </span>
      </div>
    </div>
  );
};

export default Footer;
