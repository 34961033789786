import laptopStock from "../../assets/kuva3.jpg";
import notepadStock from "../../assets/kuva4.jpg";

const PHOTO_1_WIDTH = 634;
const PHOTO_1_HEIGHT = 423;

const PHOTO_2_WIDTH = 536;
const PHOTO_2_HEIGHT = 357;

const Usage = () => (
  <div className="w-full flex flex-col justify-center m-auto text-black leading-relaxed pb-16 bg-[#e9e6d3] section-3">
    <p className="text-3xl md:text-5xl w-[80%] m-auto font-normal mt-8 mb-16 text-center">
      Mitkä toimenpiteet ovat sallittuja Tempo-rahoitusprojektissa?
    </p>
    <div className="w-full flex flex-col md:flex-row justify-center">
      <div className="flex-col m-auto w-[90%] md:w-[40%]">
        <img
          src={laptopStock}
          alt="laptop stock photo TODO"
          width={PHOTO_1_WIDTH}
          height={PHOTO_1_HEIGHT}
        />
        <p className="mb-4 w-full pt-8 text-[#0c2a38]">
          <i>Tempo</i>-projekti tulee rakentaa siten, että se koostuu
          tasapuolisesti yrityksen kasvuvisiota edesauttavista toimista.
          Projektissa tulee olla markkinoiden selvittämistä, yrityksen ja
          liiketoimintamallin kehittämistä ja asiakaspalautteen keräämistä
          sopivassa suhteessa. Yleisimpiä Tempo-rahoituksella rahoitettavia
          toimia ovat markkinaselvitysten ja kansainvälistymisstrategioiden
          tekeminen, sekä oikeilla asiakkailla tuote- ja palvelukonseptin
          testaaminen pilotoimalla. Tempo-projektiin voidaan sisällyttää myös
          esimerkiksi uudesta teknologiasta tehtävä patenttiselvitys,
          kansainvälistä kasvua tukevan johtamisen kehittämistä tai tuotteen tai
          palvelun koemarkkinointia pilotinomaisesti. Käytännössä
          Tempo-rahoitusprojekti koostuu yleensä hankkeessa suoritettavien
          tehtävien aiheuttamista palkkakustannuksista, ulkopuolisista
          asiantuntijapalveluista sekä palkkojen sivukuluista.
        </p>
        <p className="mb-4 w-full pt-8 text-[#0c2a38]">
          Business Finlandin{" "}
          <b className="underline underline-offset-1">Market Access</b>{" "}
          -ohjelmien tai ulkomaisten kiihdyttämöohjelmien
          osallistumiskustannuksia voidaan myös sisällyttää Tempo-projektiin.
          Tällaisissa tapauksissa Tempo-rahoitusta voidaan hakea
          poikkeuksellisesti toistamiseen, vaikka yleissääntö on, että sama
          yritys voi saada Tempo-rahoituksen vain kerran.
        </p>
      </div>
      <div className="ml-3 md:ml-8 w-[100%] md:w-[40%] mr-8">
        <p className="mb-4 text-[#0c2a38]">
          <i>Tempo-rahoitusprojektiin</i> voidaan sisällyttää myös
          startup-yritysten omistajien palkkoja, mikäli omistusosuus on
          vähintään 10% hakijayrityksestä. Tuettava palkkakustannus voi
          kuitenkin olla maksimissaan 3000 euroa kuukaudessa ja enintään 19
          euroa per tunti. Muita kustannuksia, joita ei erikseen tarvitse
          erotella Business Finlandille voidaan sisällyttää projektiin
          korkeintaan 20% palkkojen ja ostettujen palveluiden yhteissummasta.
          Kulurakenne on kuitenkin aina pystyttävä perustelemaan kunnolla.
        </p>
        <p className="mb-4 text-[#0c2a38]">
          Vaikka koemarkkinointia, kysynnän testaamista ja pilotointia
          asiakkailla voidaan sisällyttää <i>Tempo</i>-projektiin, on syytä
          pitää mielessä, että Tempo-rahoitusta ei ole tarkoitettu varsinaiseen
          tuotekehitykseen, myyntiin tai markkinointiin. Pähkinänkuoressa
          Tempo-rahoitusta käytetään jo pitkälle kehitetyn tuotteen tai palvelun
          menestyksekkääseen viemiseen kansainvälisille markkinoille.
        </p>
        <p className="mb-4 text-[#0c2a38]">
          Mikäli varsinaiseen tuotekehitykseen on merkittävää rahoitustarvetta,
          kannattaa tutustua Business Finlandin <br />
          <i>
            <a
              className="underline underline-offset-1"
              href="https://www.businessfinland.fi/suomalaisille-asiakkaille/palvelut/rahoitus/tutkimus-ja-kehitysrahoitus/tutkimus-ja-kehitysrahoitus"
              target="_blank"
            >
              Tutkimus, kehitys ja pilotointi {""}
            </a>
          </i>
          -rahoitukseen ja <i>ELY-keskuksen</i>
          <a
            className="underline underline-offset-1"
            href="https://www.ely-keskus.fi/yrityksen-kehittamisavustus"
            target="_blank"
          >
            {" "}
            kehittämisavustukseen.
          </a>
        </p>
        <img
          className="w-[90%] sm:w-auto"
          src={notepadStock}
          alt="laptop stock photo TODO"
          width={PHOTO_2_WIDTH}
          height={PHOTO_2_HEIGHT}
        />
      </div>
    </div>
  </div>
);

export default Usage;

/*
 
          */
