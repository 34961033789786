const jumpToSection = (v: number) => {
  document.getElementsByClassName(`section-${v}`)[0].scrollIntoView({
    behavior: "smooth",
  });
};

const Hero = () => (
  <div className="hero w-full h-[500px] flex">
    <div className="text-white flex flex-col justify-center ml-4 md:ml-20">
      <h1 className="text-4xl">Temporahoitus</h1>
      <p className="mt-4">Kasvuvara kuuluu kaikille</p>
      <button
        onClick={() => jumpToSection(1)}
        className="p-4 bg-white text-black border rounded outlined mt-4"
      >
        Lue lisää
      </button>
    </div>
  </div>
);

export default Hero;
