const Navbar = () => {
  const jumpToSection = (v: number) => {
    document.getElementsByClassName(`section-${v}`)[0].scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div className="h-[100px] bg-white w-full">
      <div className="w-full h-full m-auto py-4 px-2 md:px-8 flex items-center justify-between">
        <h1 className="text-4xl text-[#555770]">Temporahoitus.fi</h1>
        <div className="hidden md:flex">
          <div
            className="p-4 hover:bg-gray-300 rounded cursor-pointer uppercase"
            onClick={() => jumpToSection(1)}
          >
            kenelle
          </div>
          <div
            className="p-4 hover:bg-gray-300 rounded cursor-pointer uppercase"
            onClick={() => jumpToSection(2)}
          >
            paljonko
          </div>
          <div
            className="p-4 hover:bg-gray-300 rounded cursor-pointer uppercase"
            onClick={() => jumpToSection(3)}
          >
            mihin
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
