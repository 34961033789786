import laptopStock from "../../assets/kuva1.jpg";
import notepadStock from "../../assets/kuva2.jpg";

const PHOTO_1_WIDTH = 634;
const PHOTO_1_HEIGHT = 423;

const PHOTO_2_WIDTH = 439;
const PHOTO_2_HEIGHT = 292;

const ContactUrl =
  "https://momentumhelsinki.fi/?gclid=Cj0KCQiAuP-OBhDqARIsAD4XHpd3buRfJ5AUpXHkOgvIw2SwwdbE-EKdVkr7283zs71dAjtBcM17Q2caAg_8EALw_wcB";

const MoneySection = () => (
  <div className="w-full flex flex-col justify-center m-auto text-black leading-relaxed pb-16 bg-[#e9e6d3] section-2">
    <p className="text-3xl md:text-5xl w-[80%] m-auto font-normal mt-8 mb-16 text-center">
      Paljonko Tempo-rahoitusta on suuruudeltaan?
    </p>
    <div className="w-full flex flex-col md:flex-row justify-center">
      <div className="flex-col w-[100%] md:w-[50%]">
        <img
          src={laptopStock}
          alt="laptop stock photo TODO"
          width={PHOTO_1_WIDTH}
          height={PHOTO_1_HEIGHT}
        />
        <p className="px-8 md:px-0 mb-4 w-full text-center md:text-left pt-8 text-[#0c2a38]">
          Tempo-rahoitus on avustusmuotoista rahoitusta, eli sitä ei tarvitse
          maksaa takaisin. <br /> Tempo-rahoitus soveltuu sekä startup-, pk-,
          että midcap-yrityksille. <i>Business Finland</i> maksaa 75%
          Tempo-projektin kustannuksista, jotka voivat olla enimmillään 66 667
          euroa. Näin ollen Tempo-rahoitusta voi saada avustuksena maksimissaan
          50 000 euroa. Myönteisen rahoituspäätöksen jälkeen yrityksen tilille
          voidaan maksaa enintään 35 000 euron ennakko. Loppuosa
          Tempo-rahoituksesta maksetaan projektin jälkeen loppuraportin
          yhteydessä hyväksyttyjen kustannusten perusteella.
        </p>
      </div>
      <div className="px-8 md:px-0 md:ml-8 w-[100%] md:w-[30%]">
        <p className="mb-4 text-center md:text-left text-[#0c2a38]">
          Tästä syystä johtuen hakijayrityksellä tulee olla vähintään 30 000
          euroa omarahoitusosuutta Tempo-projektissa. Oman pääoman ehtoisen
          rahoituksen tulee olla kokonaan yksityisistä lähteistä, kuten
          sijoitetun vapaan oman pääoman rahasto (SVOP-sijoitus), pääomalaina
          tai yritykselle voitoista kertyneet varat. Voittovarojen tulee ilmetä
          yhtiön vahvistetusta tilinpäätöksestä.
        </p>
        <img
          src={notepadStock}
          alt="laptop stock photo TODO"
          width={PHOTO_2_WIDTH}
          height={PHOTO_2_HEIGHT}
        />
        <p className="mb-4 pt-8 text-[#0c2a38]">
          <b>
            Ota{" "}
            <a href={ContactUrl} className="underline underline-offset-1">
              yhteyttä
            </a>{" "}
            Momentumiin{" "}
          </b>{" "}
          keskustellaksesi lisää Tempo-projektin kokonaisrahoituksesta ja
          tarvittavasta omarahoitusosuudesta.
        </p>
      </div>
    </div>
  </div>
);

export default MoneySection;
