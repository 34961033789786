const InfoSection = () => (
  <div className="w-full flex flex-col justify-center m-auto bg-[#123a4c] text-white leading-relaxed">
    <p className="text-5xl font-medium my-8 text-center">
      Mitä on Tempo-rahoitus?
    </p>
    <div className="w-full flex flex-col md:flex-row justify-center">
      <div>
        <p className="mb-4 w-[80%] m-auto pt-8">
          <b>Tempo-rahoitus</b> on rahoitusta yrityksille, joiden tavoitteena on
          kasvaa merkittävästi kansainvälisillä markkinoilla.{" "}
          <a
            href="https://www.businessfinland.fi"
            className="underline underline-offset-1"
          >
            Business Finlandin{" "}
          </a>
          myöntämää Tempo-rahoitusta voidaan hyödyntää uusien kansainvälisten
          markkinoiden kartoittamiseen, tuote- tai palvelukonseptin pilotointiin
          oikeilla asiakkailla tai esimerkiksi kasvun edellyttämän
          rahoitustarpeen selvittämiseen. Usein Tempo-rahoitusprojekti koostuu
          useista yhtiön kasvua ja kansainvälistymistä tukevista toimenpiteistä.
        </p>
        <p className="mb-4 w-[80%] m-auto pt-8">
          <i>Business Finland </i> edellyttää <i>Tempo</i>-rahoitusta hakevalta
          yritykseltä olemassa olevaa tietotaitoa kansainvälisistä markkinoista
          ja kohdemarkkinoiden kilpailutilanteesta. <i>Tempo</i>-rahoitusta ei
          voi hyödyntää varsinaiseen tuotekehitykseen, eli hakijayrityksellä
          tulee olla jo kehitetty tuote- tai palvelu. Yrityksellä tulee myös
          olla tarvittava kyvykkyys kilpailuun valitulla kohdemarkkinalla.
          Business Finland Tempo-rahoitus on kilpailtua, joten pelkkä
          vähimmäiskriteerien täyttö ei aina takaa myönteistä rahoituspäätöstä.
          Hakijayritystä ja sen liiketoimintaa arvioidaan aina kokonaisuutena.
        </p>
      </div>
      <div>
        <p className="w-[80%] m-auto pt-8">
          Hakijan on myös tunnistettava vallitseva kilpailutilanne
          kohdemarkkinoilla ja tunnettava yrityksen kehittämistarpeet nyt ja
          lähitulevaisuudessa. <i>Business Finlandin</i> rahoituksissa
          innovatiivisuus ja kansainvälinen kilpailukyky ovat erittäin
          merkittäviä mittareita hankkeen menestymisen kannalta ja tämä pitää
          paikkansa myös Tempo-rahoituksen kohdalla.
        </p>
        <p className="mb-8 w-[80%] m-auto pt-8">
          Innovatiivisuudella voidaan tarkoittaa montaa asiaa. Innovatiivisuus
          voi olla uudenlaisia tuotteita ja palveluita, jotka tuovat
          markkinoille merkittävää uutta lisäarvoa. Tämän lisäksi
          innovatiivisuus voi ilmetä myös uusina lähestymistapoina, strategioina
          tai esimerkiksi innovatiivisena johtamisena. Hakijayrityksen
          kilpailukyvyn voi osoittaa ottamalla huomioon muun muassa yrityksen
          innovaation ja markkina-aseman suojaamiseen liittyvät seikat ja
          artikuloimalla innovaation uutuusarvon mahdollisimman selkeästi.
        </p>
        <p className="mb-14 w-[80%] m-auto pt-4">
          Lue lisää Tempo-rahoituksesta{" "}
          <a href="https://www.businessfinland.fi/suomalaisille-asiakkaille/palvelut/rahoitus/tempo-rahoitus">
            Business Finlandin omilta{" "}
            <span className="underline">verkkosivuilta.</span>
          </a>
        </p>
      </div>
    </div>
  </div>
);

export default InfoSection;
