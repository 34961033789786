import { useState } from "react";

const content = [
  {
    q: "Mistä voin hakea Tempo-rahoitusta?",
    a: "Kaikkia Business Finlandin rahoituksia haetaan Business Finlandin omilta verkkosivuilta asiointipalvelusta. Asiointipalveluun kirjaudutaan hakijayrityksessä allekirjoitusoikeutta omaavan henkilön verkkopankkitunnuksilla. Business Finlandin asiointipalvelun avulla liitteiden lisääminen hakemukseen sujuu myös kätevästi.",
  },
  {
    q: "Mikä on Tempo-rahoituksen ja Innovaatiosetelin välinen ero?",
    a: "Innovaatioseteli sopii tilanteeseen, jossa hakijayrityksen tuote tai palveluidea vaatii vielä jatkokehittämistä ja ulkopuolisesta asiantuntijapalvelusta olisi hyötyä asian edistämiseksi. Innovaatioseteli on tarkoitettu yhden asiantuntijapalvelun ostamiseksi. Tempo-rahoituksessa yrityksen kasvuvisio, kehittämistarpeet, tuotekehitys ja kohdemarkkina on oltava jo pitkälle mietittynä. Tästä syystä johtuen Innovaatioseteliä on loogista käyttää ennen Tempo-rahoituksen hakemista esimerkiksi tuotekehityksen loppuunviemiseksi",
  },
];

interface AccordionProps {
  question: string;
  answer: string;
}

const Accordion = ({ question, answer }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div
        onClick={() => setIsOpen((p) => !p)}
        className="flex justify-between"
      >
        <div className="p-4 flex items-center">{question}</div>
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      {isOpen && <div className="ml-6">{answer}</div>}
    </div>
  );
};

const Faq = () => (
  <div className="p-12 bg-[#e9e6d3]">
    <h1 className="text-4xl">Usein kysytyt kysymykset</h1>
    <div className="bg-white p-4 mt-4 rounded-lg">
      {content.map((c) => (
        <Accordion question={c.q} answer={c.a} />
      ))}
    </div>
  </div>
);

export default Faq;
