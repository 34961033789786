const title = "Tarkista nämä asiat ennen Tempo-rahoituksen hakemista";

const steps = [
  {
    content:
      "Tempo-rahoitusta hakevalla yrityksellä ei saa olla maksuhäiriöitä, muita hoitamattomia taloudellisia velvoitteita tai aikaisempia Business Finland -lainojen rästejä hoitamatta",
  },
  {
    content:
      "Mikäli yrityksellä on verovelkaa, josta ei verottajan kanssa ole sovittu maksusuunnitelmaa, ei Tempo-rahoitusta voida myöntää",
  },
  {
    content:
      "Yrityksen on tarvinnut toimittaa tilinpäätöstiedot Patentti- ja rekisterihallitukseen Tempo-rahoituksen saamiseksi",
  },
  {
    content:
      "Hakijayrityksen vähämerkityksellisten, eli de minimis -tukien yhteenlaskettu määrä kuluvan ja kahden edellisen verovuoden aikana ei saa ylittää 200 000 euroa. Tempo-rahoitus on de minimis -tukea ja mikäli hakijayrityksen kiintiö ylittyy ei myöskään Tempo-rahoitusta voida myöntää",
  },
  {
    content:
      "Hakijayrityksessä tulee työskennellä vähintään yksi henkilö 100% työajalla ja kaksi henkilöä 50% työajalla. Yhden henkilön tiimistä tulee keskittyä myös pääsääntöisesti yhtiön kehittämiseen ja yleensä tämä henkilö onkin toimitusjohtaja. Yhden henkilön yrityksille ei voida myöntää Tempo-rahoitusta",
  },
];

const Faq = () => {
  return (
    <div className="p-12 bg-[#123a4c] text-white">
      <h1 className="text-3xl md:text-5xl w-[90%] m-auto text-center">
        {title}
      </h1>
      <p className="mt-16">
        Business Finland edellyttää tiettyjen taloudellisten seikkojen huomioon
        ottamista ennen myöntävän rahoituspäätöksen tekemistä. Ota huomioon nämä
        seikat:
      </p>
      <ul className="p-4 mt-2 ml-4 list-disc">
        {steps.map((s) => (
          <li className="p-4">{s.content}</li>
        ))}
      </ul>
      <p className="mt-4">
        <i>Business Finland</i> painottaa vahvasti rahoituksissaan
        innovatiivisia ratkaisuja ja korostaa kansainvälistymistä sekä
        hakijayrityksen kilpailukykyä kohdemarkkinoilla. Nämä painopisteet on
        hyvä pitää mielessä myös Tempo-rahoituksessa. Yleisiä ehtoja Business
        Finland -rahoitusten ehtoja on myös yrityksen omistajasuhteen
        kotimaisuus ja liiketoiminnan kannalta olennaisten
        immateriaalioikeuksien omistus yrityksen toimesta. Tiimiin tulee pystyä
        myös työskentelemään Suomesta käsin.
      </p>
    </div>
  );
};

export default Faq;
