
import axios from "axios";

const url = "https://hstoxmaxel.execute-api.eu-central-1.amazonaws.com/prod/leads/add";

interface ILead {
  name: string;
  yTunnus: string;
  email: string;
  phone: string;
  message: string;

}


const sendLead = async (data: any) => {

  const params = new URLSearchParams();

  //loop through the data object and add each key value pair to the params object
  for (const key in data) {
    params.append(key, data[key]);
    console.log(key, data[key]);
  };


  try {
    const response = await axios.post(url, params);
    console.log(response);
    return true;
  }
  catch (error) {
    console.log(error);
    return false;
  }

  

}

export default {
  sendLead,
};
